@import url("https://fonts.googleapis.com/css?family=Kalam:400,700|Montserrat:400,400i,500,600,700i,700&display=swap");
@font-face {
  font-family: "DINRoundOT-Light";
  src: url(../fonts/DINRoundOT-Light.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINRoundOT-Bold";
  src: url(../fonts/DINRoundOT-Bold.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}